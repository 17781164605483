import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLocales } from 'react-native-localize';

// Import des traductions
import HomeTranslationFr from "../pages/Home.translation.fr";
import HomeTranslationEn from "../pages/Home.translation.en";
import LegalTermsTranslationFr from "../pages/LegalTerms.translation.fr";
import LegalTermsTranslationEn from "../pages/LegalTerms.translation.en";

const resources = {
  en: {
    translation: {
      home: HomeTranslationEn,
      legal_terms: LegalTermsTranslationEn,
    },
  },
  fr: {
    translation: {
      home: HomeTranslationFr,
      legal_terms: LegalTermsTranslationFr,
    },
  },
};

// Obtenir la première locale disponible dans les préférences de l'utilisateur
const locales = getLocales();
const selectedLanguage = locales.length > 0 ? locales[0].languageCode : 'fr';

i18n.use(initReactI18next).init({
  resources,
  lng: selectedLanguage, // Utiliser la langue déterminée par getLocales
  returnObjects: true,
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false, // React protège déjà contre XSS
  },
});

export default i18n;
