const LegalTermsTranslationEn = {
    company: "Company",
    name: "Name",
    name_value: "Deltyo SAS",
    address: "Address",
    address_value: "9 rue des colonnes, 75002 Paris, France",
    registration: "Registration number",
    registration_value: "922 538 616 R.C.S. Paris",
    vat_number: "VAT Number",
    vat_number_value: "FR 26922538616",
    capital_stock: "Capital Stock",
    capital_stock_value: "6000 €",
    contact: "Contact",
    phone_number: "Phone Number",
    phone_number_value: "+33 7 56 80 70 72",
    email: "Email",
    email_value: "contact@deltyo.com",
    executive_director: "Executive Director",
    executive_director_value: "Bastien Cadiot",
    publishing_director: "Publishing Director",
    publishing_director_value: "Bastien Cadiot",
    hosting: "Hosting",
    hosting_value: "Google Cloud Platform (Google France), 8 rue de Londres, 75009 Paris, France",
  };
  
  export default LegalTermsTranslationEn;
  