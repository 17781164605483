const LegalTermsTranslationFr = {
    company: "Entreprise",
    name: "Nom",
    name_value: "Deltyo SAS",
    address: "Adresse",
    address_value: "9 rue des colonnes, 75002 Paris, France",
    registration: "Numéro d'enregistrement",
    registration_value: "922 538 616 R.C.S. Paris",
    vat_number: "Numéro de TVA",
    vat_number_value: "FR 26922538616",
    capital_stock: "Capital social",
    capital_stock_value: "6000 €",
    contact: "Contact",
    phone_number: "Numéro de téléphone",
    phone_number_value: "+33 7 56 80 70 72",
    email: "Email",
    email_value: "contact@deltyo.com",
    executive_director: "Directeur général",
    executive_director_value: "Bastien Cadiot",
    publishing_director: "Directeur de publication",
    publishing_director_value: "Bastien Cadiot",
    hosting: "Hébergeur",
    hosting_value: "Google Cloud Platform (Google France), 8 rue de Londres, 75009 Paris, France",
  };
  
  export default LegalTermsTranslationFr;
  