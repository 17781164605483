import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../config/i18n";

// Google Analytics
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-V3P34MN28R";
ReactGA.initialize(TRACKING_ID);

export default function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const trackLinkClick = (linkName) => {
    ReactGA.event({
      category: "Navigation",
      action: `Clicked on ${linkName} link`,
    });
  };

  return (
    <div className="App">
      <div className="logo-container">
        <p className="text-deltyo">Deltyo</p>
      </div>
      <div className="footer-links">
        <a 
          href="/legal-terms" 
          rel="noopener noreferrer"
          onClick={() => trackLinkClick('Legal Terms')}
        >
          {t('home.legal_terms')}
        </a>
        <a 
          href="mailto:contact@deltyo.com"
          rel="noopener noreferrer"
          onClick={() => trackLinkClick('Contact Email')}
        >
          {t('home.contact')}
        </a>
      </div>
    </div>
  );
}