import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home, LegalTerms, Error } from "./pages";

function App() {
  const contactPaths = [
    // Base path
    "/contact", 

    // Path by lang
    "/en/contact", "/fr/contact"];
  const privacyPaths = [
    // Base path
    "/privacy", "/politique-de-confidentialite", 

    // Path by lang
    "/en/privacy", "/fr/privacy",
    "/fr/politique-de-confidentialite", "/en/politique-de-confidentialite"
  ];
  const termsPaths = [
    // Base path
    "/tos", "/terms-of-service", "/terms-and-conditions", "/conditions-generales-utilisations",

    // Path by lang
    "/en/tos", "/fr/tos",
    "/en/terms-of-service", "/fr/terms-of-service", 
    "/en/terms-and-conditions", "/fr/terms-and-conditions", 
    "/en/conditions-generales-utilisations", "/fr/conditions-generales-utilisations"
  ];
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/legal-terms" element={<LegalTerms />} />
      {contactPaths.map((path) => (
        <Route key={path} path={path} element={<RedirectToExternal url="mailto:contact@deltyo.com" />} />
      ))}
      {privacyPaths.map((path) => (
        <Route key={path} path={path} element={<RedirectToExternal url="https://getrubi.notion.site/Privacy-Policy-154b6ef3ebfa804d827cdcbe2897df01" />} />
      ))}
      {termsPaths.map((path) => (
        <Route key={path} path={path} element={<RedirectToExternal url="https://getrubi.notion.site/Terms-and-Conditions-154b6ef3ebfa80e294abd960ff194267?pvs=74" />} />
      ))}
      <Route path="/*" element={<Error />} />
    </Routes>
  );
}

function RedirectToExternal({ url }) {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return;
}

export default App;
